import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Seo from '../components/utils/seo'
import BlogContent from '../components/blog/blogContent'

const PostPage = ({ data }) => {
    const { post, settings } = data
    const title = post.seo?.title
    const description = post.seo?.description
    const image = post.seo?.image?.asset.url
    return (
        <Layout>
            <Seo title={title ? title : post.title} description={description} image={image}>
                <script type="application/ld+json">
                    {`{ 
                        "@context": "https://schema.org", 
                        "@type": "BlogPosting",
                        "headline": "${post.title}",
                        "image": "${image}",
                        "url": "${settings.general.url}/blog/${post.slug.current}",
                        "datePublished": "${post.date}",
                        "dateCreated": "${post.date}",
                        "dateModified": "${post._updatedAt}",
                        "description": "${post.seo?.description}",
                        "author": {
                            "@type": "Person",
                            "url":"${settings.general.url}",
                            "name": "${settings.general.author}"
                        }
                    }`}
                </script>
            </Seo>
            <div className='container max-w-screen-lg mx-auto my-12 px-8 xl:px-0'>
                <div className='md:grid grid-cols-2 gap-5'>
                    <GatsbyImage
                        className='max-h-96 rounded-md mb-5'
                        image={post.image.asset.gatsbyImageData}
                        alt={post.image.alt ? post.image.alt : ""}
                    />
                    <div>
                        <h1 className='text-5xl'>{post.title}</h1>
                        <h2 className='text-accent-2 text-2xl'>{post.date}</h2>
                    </div>
                </div>
                {post._rawBlogContent &&
                    <BlogContent contentBlocks={post.blogContent} _rawContentBlocks={post._rawBlogContent} />
                }
            </div>

        </Layout>
    )
}

export default PostPage

export const query = graphql`
    query sanityPostQuery($slug: String) {
        post: sanityPost(slug: {current: {eq: $slug}}) {
            title
            slug {
                current
            }
            image {
                asset {
                    gatsbyImageData
                }
                alt
            }
            seo {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
            date(formatString: "Do MMMM YYYY")
            _updatedAt(formatString: "DD MM YYYY")
            ...BlogPageContentFragment
            _rawBlogContent(resolveReferences: {maxDepth: 10})
        }
        settings: sanitySettings {
            general {
                url
                author
            }
        }
    }`