import React from 'react'
import PageContent from '../homepage/pageContent'
import Gallery from '../homepage/gallery'


const BlogContent = (props) => {
    const { type, contentBlocks, _rawContentBlocks } = props
    const Components = {
        pageContent: PageContent,
        gallery: Gallery
    };
    return contentBlocks.map((block, index) => {
        if (Components[block._type]) {
            return React.createElement(Components[block._type], {
                key: block._key,
                block: block,
                type,
                raw: _rawContentBlocks[index],
            });
        } else {
            return null
        }
    })
}

export default BlogContent
